<script setup lang="ts">
  import { ref, watch, onMounted } from 'vue'
  import { useDropZone, useFileDialog } from '@vueuse/core'
  import { useLocalImportStore } from '@/stores/local-import'
  import { useCreatorStore } from '@/stores/creator'

  const dropZoneRef = ref<HTMLDivElement>()
  const localImport = useLocalImportStore()
  const creatorStore = useCreatorStore()
  creatorStore.reset()

  const { isOverDropZone } = useDropZone(dropZoneRef, (files) => {
    localImport.detectUpload(files)
  })

  const { open, files } = useFileDialog({
    accept: [
      '.doc,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'audio/mp4,.wav,audio/aac,audio/x-aac,audio/wav,audio/mpeg,audio/mp3,audio/x-hx-aac-adts,audio/vnd.dlna.adts,audio/x-m4a,.m4a',
      '.pdf,application/pdf',
      'video/mp4,video/x-ms-asf,video/quicktime',
    ].join(','),
    multiple: false,
  })

  watch(files, (newFiles) => {
    if (newFiles && newFiles.length > 0) {
      localImport.detectUpload(Array.from(newFiles))
    }
  })

  defineProps<{
    to?: string
  }>()

  onMounted(() => {
    localImport.dndFile = null
  })

  const handleClick = () => {
    open()
  }
</script>

<template>
  <div
    ref="dropZoneRef"
    class="px-4 py-2 bg-white-gray border border-white-gray rounded-lg justify-start items-center gap-3 inline-flex hover:border-black-90 select-none cursor-pointer"
    :class="{ 'border-dashed border-blue-70 border-2': isOverDropZone === true }"
    @click="handleClick"
  >
    <div class="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex flex-1 overflow-hidden">
      <div class="self-stretch justify-center items-center gap-2 inline-flex text-xs">
        <des-icon icon="fa-light fa-cloud-arrow-up" />
        <div class="self-stretch text-blue-10 text-sm font-normal leading-none truncate">
          Drag and drop or
          <span class="text-blue-50">browse</span>
        </div>
      </div>

      <div class="self-stretch text-black-30 text-xs font-normal leading-none truncate text-center">
        PDF, Word, Mp3, Mp4
      </div>
    </div>
  </div>
</template>
