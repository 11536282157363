<script setup lang="ts">
  import DesNavigationPanel from '@/components/navigation/DesNavigationPanel.vue'
  import DesNavigation from '@/components/navigation/DesNavigation.vue'
  import DesNavigationItem from '@/components/navigation/DesNavigationItem.vue'
  import DesNavigationCredits from '@/components/navigation/DesNavigationCredits.vue'
  import DesFeedback from '@/components/feedback/DesFeedback.vue'
  import DesTooltip from '@/components/DesTooltip.vue'
  import DesPopup from '@/components/DesPopup.vue'
  import DesModal from '@/components/modal/DesModal.vue'
  import DesLoader from '@/components/DesLoader.vue'
  import DesSnackbar from '@/components/DesSnackbar.vue'
  import DesHomeMenu from '@/components/home/DesHomeMenu.vue'
  import DesRedBarAlert from '@/components/home/DesRedBarAlert.vue'
  import DesTour from '@/components/DesTour.vue'
  import { useMenuStore } from '@/stores/menu'
  import { useUpgradeStore } from '@/stores/upgrade'
  import { useBrowserStore } from '@/stores/browser'
  import { track } from '@/helpers/mixpanelDes'
  import { useUserStore } from '@/stores/user'

  const user = useUserStore()
  const browser = useBrowserStore()
  const menu = useMenuStore()
  const upgrade = useUpgradeStore()

  function upgradeClicked() {
    track('upgrade-nav-clicked')
    upgrade.showUpgradeModal()
  }

  function promoteClicked() {
    track('promote-nav-clicked')
    browser.openNewWindowTab('https://link.designrr.io/promote')
  }
</script>

<template>
  <div class="relative min-h-screen text-blue-10">
    <header
      class="sticky top-0 flex items-center justify-between h-16 px-5 border-b border-white-gray bg-white z-50 gap-6"
    >
      <RouterLink to="/" class="shrink-0">
        <img class="h-6" src="//app.designrr.io/assets/images/logo.png" alt="" />
      </RouterLink>
      <slot name="search"></slot>
    </header>

    <DesNavigationPanel>
      <DesNavigation>
        <slot name="menu">
          <DesHomeMenu />
        </slot>
      </DesNavigation>
      <div class="flex-grow"></div>
      <DesNavigationCredits v-if="menu.isOpen && !user.isClientAccount()" />
      <DesNavigation>
        <DesNavigationItem icon="fa-light fa-star" name="Upgrades" @click="upgradeClicked()" />
        <DesNavigationItem icon="fa-light fa-bullhorn" name="Promote" @click="promoteClicked()" />
        <DesNavigationItem
          v-if="
            !user.isClientAccount() &&
            (user.user.permissions.superuser === 1 || user.user.permissions['templates.editor'] === 1)
          "
          highlighted
          @click="user.goToOldDashboard()"
          icon="fa-light fa-arrow-right-from-bracket"
          name="Switch to old design"
        />
      </DesNavigation>
    </DesNavigationPanel>

    <main class="px-6 py-9 bg-white" :class="menu.isOpen ? 'panel-opened' : 'ml-16'">
      <slot name="main"></slot>
    </main>

    <DesFeedback />
    <DesModal />
    <DesPopup />
    <DesTooltip />
    <DesLoader />
    <DesSnackbar />
    <DesRedBarAlert />
    <DesTour />
  </div>
</template>

<style scoped>
  .panel-opened {
    margin-left: max(theme('spacing.40'), theme('spacing.navigation'));
  }
</style>
