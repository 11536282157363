<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import DesNavigationPanel from '@/components/navigation/DesNavigationPanel.vue'
  import DesNavigation from '@/components/navigation/DesNavigation.vue'
  import DesNavigationItem from '@/components/navigation/DesNavigationItem.vue'
  import DesFeedback from '@/components/feedback/DesFeedback.vue'
  import DesTooltip from '@/components/DesTooltip.vue'
  import DesPopup from '@/components/DesPopup.vue'
  import DesModal from '@/components/modal/DesModal.vue'
  import DesLoader from '@/components/DesLoader.vue'
  import DesSnackbar from '@/components/DesSnackbar.vue'
  import DesRedBarAlert from '@/components/home/DesRedBarAlert.vue'
  import DesTour from '@/components/DesTour.vue'
  import { useMenuStore } from '@/stores/menu'
  import { useBrowserStore } from '@/stores/browser'
  import { track } from '@/helpers/mixpanelDes'
  import { useUserStore } from '@/stores/user'
  import { useClientStore } from '@/stores/client'
  import { getAgencyFromUrl, getAgencyInfo, redirectToAgency } from '@/helpers/agency'
  import DesMenu from '@/components/menu/DesMenu.vue'
  import DesMenuItem from '@/components/menu/DesMenuItem.vue'
  import DesMenuSeparator from '@/components/menu/DesMenuSeparator.vue'
  import DesBarAvatar from '@/components/bar/DesBarAvatar.vue'
  import type { TAgency } from '@/types/agency'

  const user = useUserStore()
  const client = useClientStore()
  const browser = useBrowserStore()
  const menu = useMenuStore()
  const logo = ref<string>('//app.designrr.io/assets/images/logo.png')
  const agency = ref<TAgency | null>(null)

  async function logout() {
    await client.performLogout()
    user.logout()
  }

  function promoteClicked() {
    track('promote-nav-clicked')
    browser.openNewWindowTab('https://link.designrr.io/promote')
  }

  onMounted(async () => {
    client.getDashboard()
    const agencySlug = getAgencyFromUrl()

    if (agencySlug) {
      const agencyRes = await getAgencyInfo(agencySlug)

      if (agencyRes.result) {
        agency.value = agencyRes.agency
        logo.value = agencyRes.agency.logo ?? logo.value
      } else if ('redirect' in agencyRes && agencyRes?.redirect) {
        redirectToAgency(agencyRes)
      }
    }
  })
</script>

<template>
  <div class="relative min-h-screen text-blue-10">
    <header
      class="sticky top-0 flex items-center justify-between h-16 px-5 border-b border-white-gray bg-white z-50 gap-6"
    >
      <RouterLink to="/client" class="shrink-0">
        <img class="h-6" v-if="logo" :src="logo" alt="" />
      </RouterLink>

      <slot name="search"></slot>

      <div class="flex gap-6 items-center shrink-0">
        <DesMenu bottom>
          <template #toggle>
            <DesBarAvatar />
          </template>
          <DesMenuItem to="/client/account" icon="fa-light fa-user">Account settings</DesMenuItem>
          <DesMenuSeparator />
          <DesMenuItem icon="fa-light fa-arrow-right-from-bracket" @click="logout()">Log out</DesMenuItem>
        </DesMenu>
      </div>
    </header>

    <DesNavigationPanel>
      <DesNavigation>
        <DesNavigationItem icon="fa-light fa-folder" name="My projects" to="/client" />
      </DesNavigation>
    </DesNavigationPanel>

    <main class="px-6 py-9 bg-white" :class="menu.isOpen ? 'panel-opened' : 'ml-16'">
      <slot name="main"></slot>
    </main>

    <DesFeedback />
    <DesModal />
    <DesPopup />
    <DesTooltip />
    <DesLoader />
    <DesSnackbar />
    <DesRedBarAlert />
    <DesTour />
  </div>
</template>

<style scoped>
  .panel-opened {
    margin-left: max(theme('spacing.40'), theme('spacing.navigation'));
  }
</style>
