import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAlignJustify,
  faAlignLeft,
  faAlignSlash,
  faArrowDown,
  faArrowDownToBracket,
  faArrowDownToSquare,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightLong,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUp,
  faArrowUpFromBracket,
  faArrowUpRight,
  faArrowsMaximize,
  faArrowsUpDownLeftRight,
  faB,
  faBell,
  faBookOpen,
  faBriefcase,
  faBullhorn,
  faCameraViewfinder,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleNotch,
  faCirclePlay,
  faCircleQuestion,
  faCity,
  faClock,
  faClockRotateLeft,
  faCloud,
  faCloudArrowUp,
  faCloudCheck,
  faCode,
  faCog,
  faCoins,
  faCommand,
  faCommentLines,
  faCopy,
  faCreditCard,
  faCube,
  faEllipsis,
  faEllipsisVertical,
  faExclamation,
  faExclamationCircle,
  faEye,
  faFile,
  faFileCode,
  faFileInvoice,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFolder,
  faFolderPlus,
  faGear,
  faGraduationCap,
  faGrid2,
  faGripLines,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faHeadphones,
  faHeart,
  faHighlighter,
  faImage,
  faInfo,
  faInfoCircle,
  faItalic,
  faKeyboard,
  faLaptopMobile,
  faLink,
  faListCheck,
  faListOl,
  faListUl,
  faLock,
  faMagnifyingGlass,
  faMerge,
  faNote,
  faObjectGroup,
  faParagraphRtl,
  faPause,
  faPen,
  faPenField,
  faPeopleGroup,
  faPlay,
  faPlus,
  faQrcode,
  faQuoteRight,
  faRectangle,
  faRectangleVertical,
  faRectanglesMixed,
  faRefresh,
  faRemoveFormat,
  faRotateLeft,
  faSearch,
  faShoppingBasket,
  faSignPost,
  faSparkles,
  faSpellCheck,
  faSquare,
  faStar,
  faStrikethrough,
  faSync,
  faTags,
  faText,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faUndo,
  faUser,
  faUserGroup,
  faUserGroupSimple,
  faUserTie,
  faVideo,
  faWaveSine,
  faRepeat,
  faPaintbrushPencil,
  faImages,
  faSubtitles,
} from '@fortawesome/pro-light-svg-icons'
import {
  faBolt as fsBolt,
  faBriefcase as fsBriefcase,
  faCirclePlay as fsCirclePlay,
  faCrown as fsCrown,
  faHeadphones as fsHeadphones,
  faHeart as fsHeart,
  faStar as fsStar,
} from '@fortawesome/pro-solid-svg-icons'
import { faYoutube as fbYoutube, faSquareFacebook as fbSquareFacebook } from '@fortawesome/free-brands-svg-icons'
import { DesTooltip } from './directives/DesTooltip'
import { DesHorizontalScroll } from './directives/DesHorizontalScroll'
import { DesTour } from './directives/DesTour'
import { DesTrack } from './directives/DesTrack'
import App from './App.vue'
import router from './router'
import './index.css'

const app = createApp(App)

library.add(
  // @ts-ignore
  faAlignJustify,
  faAlignLeft,
  faAlignSlash,
  faArrowDown,
  faArrowDownToBracket,
  faArrowDownToSquare,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightLong,
  faArrowRightFromBracket,
  faArrowsMaximize,
  faArrowsUpDownLeftRight,
  faArrowUp,
  faArrowUpFromBracket,
  faArrowUpRight,
  faB,
  faBell,
  faBookOpen,
  faBriefcase,
  faBullhorn,
  faCameraViewfinder,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleNotch,
  faCirclePlay,
  faCircleQuestion,
  faCity,
  faClock,
  faClockRotateLeft,
  faCloud,
  faCloudArrowUp,
  faCloudCheck,
  faCode,
  faCog,
  faCoins,
  faCommand,
  faCommentLines,
  faCopy,
  faCreditCard,
  faCube,
  faEllipsis,
  faEllipsisVertical,
  faExclamation,
  faExclamationCircle,
  faEye,
  faFile,
  faFileCode,
  faFileInvoice,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFolder,
  faFolderPlus,
  faGear,
  faGraduationCap,
  faGrid2,
  faGripLines,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faHeadphones,
  faHeart,
  faHighlighter,
  faImage,
  faImages,
  faInfo,
  faInfoCircle,
  faItalic,
  faKeyboard,
  faLaptopMobile,
  faLink,
  faListCheck,
  faListOl,
  faListUl,
  faLock,
  faMagnifyingGlass,
  faMerge,
  faNote,
  faObjectGroup,
  faPaintbrushPencil,
  faParagraphRtl,
  faPause,
  faPen,
  faPenField,
  faPeopleGroup,
  faPlay,
  faPlus,
  faQrcode,
  faQuoteRight,
  faRectangle,
  faRectanglesMixed,
  faRectangleVertical,
  faRefresh,
  faRemoveFormat,
  faRepeat,
  faRotateLeft,
  faSearch,
  faShoppingBasket,
  faSignPost,
  faSparkles,
  faSpellCheck,
  faSquare,
  faStar,
  faStrikethrough,
  faSubtitles,
  faSync,
  faText,
  faTags,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faUndo,
  faUser,
  faUserGroup,
  faUserGroupSimple,
  faUserTie,
  faVideo,
  faWaveSine,
  fbSquareFacebook,
  fbYoutube,
  fsBolt,
  fsBriefcase,
  fsCirclePlay,
  fsCrown,
  fsHeadphones,
  fsHeart,
  fsStar,
)

app.use(createPinia())
app.use(router)
app.component('des-icon', FontAwesomeIcon)
app.component('des-icon-layers', FontAwesomeLayers)
app.directive('des-tooltip', DesTooltip)
app.directive('des-horizontal-scroll', DesHorizontalScroll)
app.directive('des-tour', DesTour)
app.directive('des-track', DesTrack)
app.mount('#app')
