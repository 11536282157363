import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { ERemoteImportURLType } from '@/types/remote-imports'
import { useRemoteImportStore } from '@/stores/remote-import'
import { useLocalImportStore } from '@/stores/local-import'
import { useSnackbarStore } from '@/stores/snackbar'
import { useTagsStore } from '@/stores/tags'
import { useUserStore } from '@/stores/user'
import { useUpgradeStore } from '@/stores/upgrade'
import { useTemplatesStore } from '@/stores/templates'
import { useRouter } from 'vue-router'
import { http } from '@/helpers/http'
import { track } from '@/helpers/mixpanelDes'
import type { TTagHint } from '@/types/tags'
import type { TProjectCreateResult } from '@/types/projects'
import type { TDropdownOption } from '@/components/form/DesDropdown.vue'
import type { TTemplate, TPageSize } from '@/types/templates'
import { ESourceStep } from '@/types/creators'

export type TCreatorOption = {
  label?: string
  name: string
  image?: string
  description?: string
  more?: boolean
  type?: ERemoteImportURLType
  multiple?: boolean
  fileUpload?: boolean
  enabled?: () => boolean
  accept?: string
  bookTypes?: string[]
  tooltip?: string
}

export const useCreatorStore = defineStore('creator', () => {
  const tagStore = useTagsStore()
  const remoteImport = useRemoteImportStore()
  const localImport = useLocalImportStore()
  const snack = useSnackbarStore()
  const user = useUserStore()
  const router = useRouter()
  const upgrade = useUpgradeStore()
  const templates = useTemplatesStore()
  const selectImportInProgress = ref(false)
  const arrivedFromCreator = ref<boolean>(false)
  const skipPublicationTypeReset = ref<boolean>(false)

  watch(router.currentRoute, (newRoute, oldRoute) => {
    arrivedFromCreator.value = !!(
      oldRoute.name &&
      newRoute.name &&
      ['creator', 'drafts', 'draft-ebook', 'start-from-scratch'].includes(oldRoute.name.toString()) &&
      ['templates', 'draft-ebook'].includes(newRoute.name.toString())
    )

    const enterCreator = (newRoute.name === 'creator' || newRoute.name === 'wordgenie') && oldRoute.name !== 'creator'
    const typeChanged = newRoute.params.type !== oldRoute.params.type && newRoute.name === 'creator'

    if ((enterCreator || typeChanged) && !localImport.dndFile) {
      reset()
    }

    if (enterCreator && importTypes.map((e) => e.name).includes(newRoute.params.import?.toString())) {
      // open dropdown if user selected import type
      selectImportInProgress.value = true
    }
  })

  const transcriptionLanguages = [
    { name: 'en', label: '🇬🇧 English' },
    { name: 'nl', label: '🇳🇱 Dutch (beta)' },
    { name: 'fi', label: '🇫🇮 Finnish (beta)' },
    { name: 'fr', label: '🇫🇷 French' },
    { name: 'de', label: '🇩🇪 German' },
    { name: 'hu', label: '🇭🇺 Hungarian' },
    { name: 'it', label: '🇮🇹 Italian (beta)' },
    { name: 'pl', label: '🇵🇱 Polish (beta)' },
    { name: 'pt', label: '🇵🇹 Portuguese (beta)' },
    { name: 'es', label: '🇪🇸 Spanish' },
  ]

  // step 1
  const bookTypes: TCreatorOption[] = [
    {
      label: 'eBook',
      name: 'ebook',
      image: '/dashboard/new-project/type-ebook.png',
      description: 'Craft Words and ignite Imagination',
    },
    {
      label: 'Audiobook',
      name: 'audiobook',
      image: '/dashboard/new-project/type-audiobook.png',
      description: 'Stories Come Alive Vocally',
      enabled: () => user.accountType.tts_credits > 0,
    },
  ]
  const bookType = ref<TCreatorOption | null>(null)

  function selectBookType(option: TCreatorOption, trackEvent: boolean = false, skipRedirect: boolean = false) {
    const initName = bookType.value?.name
    if (option.enabled && !option.enabled()) {
      upgrade.showUpgradeModal()
      return
    }

    if (option.name === 'flipbook') {
      sourceStep.value = ESourceStep.ImportOption
    }

    bookType.value = option

    if (option.name !== initName) {
      if (trackEvent) {
        track('project-creating-type', { type: option.name })
      }
      if (!skipRedirect) {
        router.push(`/creator/${bookType.value?.name}`)
      }
    }
  }

  // step 2
  const publicationTypes: TCreatorOption[] = [
    {
      label: 'PDF',
      name: 'pdf',
      image: '/dashboard/new-project/to-pdf.png',
      description: 'For adobe reader',
    },
    {
      label: 'Flipbook',
      name: 'flipbook',
      image: '/dashboard/new-project/to-flipbook.png',
      description: 'Set your book in motion',
      enabled: () => user.accountType.flipbook_publish > 0,
    },
    {
      label: 'Kindle',
      name: 'kindle',
      image: '/dashboard/new-project/to-kindle.png',
      description: 'E-pub export',
      enabled: () => user.accountType.ebook_export > 0,
    },
    {
      label: 'HTML',
      name: 'html',
      image: '/dashboard/new-project/to-html.png',
      description: 'Export html',
      enabled: () => user.accountType.html_export > 0,
    },
    {
      label: 'EPUB',
      name: 'epub',
      image: '/dashboard/new-project/to-epub.png',
      description: 'For e-readers',
      enabled: () => user.accountType.ebook_export > 0,
    },
    {
      label: 'Live ebook',
      name: 'liveebook',
      image: '/dashboard/new-project/to-liveebook.png',
      description: 'new',
      enabled: () => user.accountType.live_ebook_export > 0,
    },
    {
      label: "I don't know yet, will decide later",
      name: 'unknown',
    },
  ]
  const publicationType = ref<TCreatorOption | null>(null)

  function selectPublicationType(option: TCreatorOption) {
    if (option.enabled && !option.enabled()) {
      upgrade.showUpgradeModal()
      return
    }
    publicationType.value = option

    //do the refresh for from-pdf to pdf-to-flipbook if user is importing from pdf and switches from flipbook to pdf or reverse
    if (!importType.value) {
      return
    }
    let importTypeName = importType.value.name
    if (importTypeName === 'from-pdf' && publicationType.value?.name === 'flipbook') {
      importTypeName = 'pdf-to-flipbook'
    } else if (importTypeName === 'pdf-to-flipbook' && publicationType.value?.name !== 'flipbook') {
      importTypeName = 'from-pdf'
    }

    if (importTypeName !== importType.value.name) {
      //the url needs to be changed
      router.push(`/creator/${bookType.value?.name}/${importTypeName}`)
    }
  }

  // step 3
  const sources: TCreatorOption[] = [
    {
      label: 'Upload file/Insert link',
      name: 'from-file',
      // image: '/dashboard/new-project/start-file.png',
      image: '/dashboard/new-project/ico-from-file.png',
      description: 'Click to see all the options',
      more: true,
      bookTypes: ['ebook', 'audiobook'],
    },
    {
      label: 'From existing doc',
      name: 'from-docs',
      image: '/dashboard/new-project/ico-from-doc.png',
      description: 'Choose a file from your docs',
      more: true,
      bookTypes: ['ebook', 'audiobook'],
    },
    {
      label: 'Use wordgenie',
      name: 'from-wordgenie',
      // image: '/dashboard/new-project/start-generate.png',
      image: '/dashboard/new-project/ico-from-wordgenie.png',
      description: 'Generate with AI',
      enabled: () => user.accountType.ai_credits > 0,
      bookTypes: ['ebook'],
    },
    {
      label: 'Start writing',
      name: 'from-scratch',
      // image: '/dashboard/new-project/start-writing.png',
      image: '/dashboard/new-project/ico-from-scratch.png',
      description: 'From scratch',
      bookTypes: ['ebook', 'audiobook'],
    },
    // {
    //   label: 'Merge docs',
    //   name: 'merge-drafts',
    //   image: '/dashboard/new-project/ico-merge-drafts.png',
    //   description: 'Merge one or more docs'
    // },
    {
      label: 'Choose a template',
      name: 'from-template',
      image: '/dashboard/new-project/ico-from-template.png',
      description: 'Start from template',
      bookTypes: ['ebook'],
    },
  ]

  const source = ref<TCreatorOption | null>(null)
  const sourceStep = ref<number>(ESourceStep.SelectBaseline)

  function setPublicationTypeToFlipbook() {
    const option = publicationTypes.find((obj) => obj.name === 'flipbook')
    if (!option) {
      return
    }

    selectPublicationType(option)
    skipPublicationTypeReset.value = true
  }

  function selectSource(option: TCreatorOption, skipRedirect: boolean = false) {
    const initName = source.value?.name
    if (option.enabled && !option.enabled()) {
      upgrade.showUpgradeModal()
      return
    }

    source.value = option
    importType.value = null

    if (option.name === 'from-docs') {
      sourceStep.value = ESourceStep.Docs
    } else if (option.name === 'from-file') {
      sourceStep.value = ESourceStep.ImportOption
    } else {
      sourceStep.value = ESourceStep.SelectBaseline
    }

    if (option.name !== initName) {
      track('project-creating-source', { type: source.value.name })
      if (!skipRedirect) {
        router.push(`/creator/${bookType.value?.name}/${source.value.name}`)
      }
    }
  }

  const importTypes: TCreatorOption[] = [
    {
      image: '/dashboard/new-project/ico-from-docx.png',
      name: 'from-docx',
      more: true,
      type: ERemoteImportURLType.DocX,
      accept: '.doc,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      bookTypes: ['ebook', 'audiobook'],
      tooltip: `Before uploading your Word Docx, remove headers &amp; footers, footnotes and table of contents (Designrr can recreate them after import). Then check you have your headings defined. Click <a target="_blank" href="https://intercom.help/designrr/en/articles/2391519-how-to-format-a-word-docx">here</a> for more information (opens in new window)`,
    },
    {
      image: '/dashboard/new-project/ico-from-gdoc.png',
      name: 'from-gdoc',
      more: true,
      multiple: true,
      type: ERemoteImportURLType.GDoc,
      fileUpload: false,
      bookTypes: ['ebook', 'audiobook'],
      tooltip: `Importing from Google DOCS, ensure you have shared the URL to the public so Designrr can read it. Then copy the URL to clipboard. Click <a href="https://intercom.help/designrr/en/articles/3197210-how-to-import-from-google-docs" target="_blank">here</a> to learn how (opens in new window)`,
    },
    {
      image: '/dashboard/new-project/ico-from-blog.png',
      name: 'from-blog',
      more: true,
      type: ERemoteImportURLType.Web,
      multiple: true,
      bookTypes: ['ebook', 'audiobook'],
      tooltip: `When importing from a website, make sure you copy full the page URL not the homepage URL. EG: https://designrr.io/how-to-write-an-ebook-in-7-steps/ NOT https://designrr.io`,
    },
    {
      image: '/dashboard/new-project/ico-from-youtube.png',
      name: 'youtube',
      more: true,
      type: ERemoteImportURLType.YouTube,
      fileUpload: false,
      enabled: () => user.accountType.import_from_video > 0,
      bookTypes: ['ebook'],
      tooltip: `Enter the YouTube URL from the browser address bar. Make sure your video is not private or unlisted. Click <a href='https://intercom.help/designrr/en/articles/2044749-how-to-import-audio-and-video-files' target='_blank'>here</a> for more info (opens in new window)`,
    },
    {
      image: '/dashboard/new-project/ico-from-podcast.png',
      name: 'from-podcast',
      more: true,
      type: ERemoteImportURLType.Audio,
      enabled: () => user.accountType.import_from_podcast > 0,
      accept:
        'audio/mp4,.wav,audio/aac,audio/x-aac,audio/wav,audio/wav,audio/x-wav,audio/mpeg,audio/mp3,audio/x-hx-aac-adts,audio/vnd.dlna.adts,audio/x-m4a,.m4a',
      bookTypes: ['ebook'],
      tooltip: `Ensure the file you're uploading ends in .mp3 and is no larger than 500MB`,
    },
    {
      image: '/dashboard/new-project/ico-from-pdf.png',
      name: 'from-pdf',
      more: true,
      type: ERemoteImportURLType.Pdf,
      enabled: () => user.accountType.import_from_pdf > 0,
      accept: '.pdf,application/pdf',
      bookTypes: ['ebook', 'audiobook'],
      tooltip: `When uploading a PDF, be sure to check its a textual PDF and not an Image PDF. Images can't be turned into text. Read more <a href='https://intercom.help/designrr/en/articles/646255-how-to-import-a-pdf' target='_blank'>here</a> (opens in new window)`,
    },
    {
      image: '/dashboard/new-project/ico-from-pdf.png',
      name: 'pdf-to-flipbook',
      more: true,
      type: ERemoteImportURLType.Flipbook,
      enabled: () => user.accountType.flipbook_projects > 0,
      accept: '.pdf,application/pdf',
      bookTypes: ['flipbook'],
      tooltip: `Upload any PDF to be turned into a flipbook. You'll be given a share URL you can email or share on social media, or you can embed into a webpage. Read more <a href='https://intercom.help/designrr/en/articles/9158409-pdf-to-flipbook' target='_blank'>here</a> for more info (opens in new window)`,
    },
    {
      image: '/dashboard/new-project/ico-from-video.png',
      name: 'from-video',
      more: true,
      type: ERemoteImportURLType.Video,
      enabled: () => user.accountType.import_from_video > 0,
      accept: 'video/mp4,video/x-ms-asf,video/quicktime',
      bookTypes: ['ebook'],
      tooltip: `Ensure the file you're uploading ends in .mp4/.mov and is no larger than 500MB. Once uploaded Designrr will transcribe and extract the text and images into your project`,
    },
  ]
  const importType = ref<TCreatorOption | null>(null)

  function formatTagsForNewProject(dropdownTags?: { label: string; name: string }[]): {
    id: string
    name: string
  }[] {
    if (!dropdownTags?.length) {
      return []
    }
    return dropdownTags.map((tag) => ({
      id: tag.name,
      name: tag.label,
    }))
  }

  async function createProject(template: TTemplate, pageSize: string, landscape: boolean) {
    const size: TPageSize | null = templates.allPageSizes.find((item) => item.name === pageSize) ?? null
    if (!size) {
      snack.add('Wrong page size selected')
      return
    }
    if (landscape) {
      size.landscape = landscape
      const w = size.width
      const h = size.height
      size.width = h
      size.height = w
    }

    if (source?.value?.name?.toString() !== 'from-template') {
      await http.post('/projects/import_proxy', {
        title: remoteImport.draftTitle,
        content: remoteImport.content,
      })
    }

    const res = await http.post<TProjectCreateResult>(`/projects`, {
      draft_id: remoteImport.draftId >= 0 ? remoteImport.draftId : null,
      name: remoteImport.draftTitle,
      newTemplateSystem: template.new_template_system,
      pageHeight: size.height,
      pageSize: pageSize,
      pageWidth: size.width,
      tags: formatTagsForNewProject(tagsListDropdown?.value),
      template: template.id,
      templateName: template.name,
      type: 'ebook',
    })
    if (res.id) {
      window.location.href = `/app/#/builder/${res.slug_id}/${res.slug}`
      return res.id
    }
    snack.add('Error while creating project')
    throw 'Error while creating project'
  }

  function selectImportType(option: TCreatorOption, skipRedirect: boolean = false) {
    const initName = importType.value?.name
    if (option.enabled && !option.enabled() && !flipbookPublicationWithPdfImport(option.name)) {
      upgrade.showUpgradeModal()
      return
    }

    importType.value = option
    remoteImport.urls = []
    sourceStep.value = ESourceStep.LinkOrFile

    const defaultSource = sources.find((e) => e.name === 'from-file')
    if (defaultSource) {
      source.value = defaultSource
    }

    if (!option.type) {
      importType.value = null
      sourceStep.value = ESourceStep.SelectBaseline
      snack.add('Selected import type is not supported')
      throw new Error('No import type selected')
    }

    if (option.name !== initName) {
      let importTypeName = importType.value.name
      if (importTypeName === 'from-pdf' && publicationType.value?.name === 'flipbook') {
        importTypeName = 'pdf-to-flipbook'
      }

      track('project-creating-source', { type: importTypeName })
      if (!skipRedirect) {
        router.push(`/creator/${bookType.value?.name}/${importTypeName}`)
      }
    }

    remoteImport.init(option.type)
    localImport.init(option.type, importType?.value.accept)
  }

  // step 5
  const tagsList = ref<TTagHint[]>([])
  const tagsDropdownHints = computed(() =>
    tagStore.hints.map((e) => ({
      label: e.name,
      name: e.id.toString(),
    })),
  )
  const tagsListDropdown = computed<TDropdownOption[]>({
    get: () =>
      tagsList.value.map((e) => ({
        label: e.name,
        name: e.id.toString(),
      })),
    set: (newList: TDropdownOption[]) => {
      tagsList.value = tagStore.hints.filter((e) => newList.some((t) => t.name === e.id.toString()))
    },
  })

  function addTagToList(tag: TDropdownOption) {
    if (tagsList.value.find((t) => t.name === tag.label)) {
      return
    }

    if (!tagsList.value.some((t) => t.name === tag.name)) {
      tagsList.value.push({
        id: parseInt(tag.name),
        name: tag.label,
        icon: null,
      })
    }
  }

  function reset() {
    bookType.value = null
    source.value = null
    sourceStep.value = ESourceStep.SelectBaseline
    importType.value = null
    tagsList.value = []
    remoteImport.draftId = -1
    localImport.urls = []

    if (skipPublicationTypeReset.value) {
      skipPublicationTypeReset.value = false
    } else {
      publicationType.value = null
    }
  }

  function resetImportType() {
    sourceStep.value = ESourceStep.ImportOption
    remoteImport.urls = []
    localImport.urls = []
    remoteImport.draftId = -1
  }

  function flipbookPublicationWithPdfImport(name: string): boolean {
    return publicationType?.value?.name === 'flipbook' && name === 'from-pdf'
  }

  return {
    arrivedFromCreator,
    selectImportInProgress,
    bookTypes,
    bookType,
    selectBookType,
    publicationTypes,
    publicationType,
    selectPublicationType,
    setPublicationTypeToFlipbook,
    flipbookPublicationWithPdfImport,
    skipPublicationTypeReset,
    sources,
    source,
    sourceStep,
    selectSource,
    importTypes,
    importType,
    selectImportType,
    tagsList,
    tagsDropdownHints,
    tagsListDropdown,
    transcriptionLanguages,
    resetImportType,
    createProject,
    addTagToList,
    reset,
  }
})
